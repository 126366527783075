import { useMediaQuery, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";

export type PageHeaderProps = {
	title: string;
};
export function PageHeader({ title }: PageHeaderProps) {
	const theme = useTheme();
	const breakpoint = useMediaQuery(theme.breakpoints.down("md"));
	return (
		<Typography fontSize={breakpoint ? "1.35rem" : "1.875rem"} lineHeight={1} fontWeight={500}>
			{title}
		</Typography>
	);
}

import { alpha, Box, Button, darken, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, hexToRgb, Typography, useTheme } from "@mui/material";
import { DialogWrap, FormCheckbox, FormElementWrap, FormInput, FormPhoneInput, httpService, LookupItem, RadioGroupField, RadioGroupFieldOption, useForm, useLookupQuery, useMyAccount, usePopupAlert } from "@sal/onevent-portal";
import { useEffect, useMemo, useState } from "react";
import { RadioGroupFieldVerticle } from "./RadioGroupFieldVerticle";
import { z } from "zod";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { Add, DeleteOutline, Edit } from "@mui/icons-material";

const subscriptionFormValidation = z
	.object({
		notificationReceipient: z.string({
			required_error: "Please select who should receive notifications",
			invalid_type_error: "Please select who should receive notifications"
		}).min(1, "Please select who should receive notifications"),

		notificationType: z.string({
			required_error: "Please select a notification type",
			invalid_type_error: "Please select a notification type"
		}).min(1, "Please select a notification type"),

		recipientName: z.string().optional().nullable(), // Will be required conditionally

		emailSelected: z.boolean(),
		smsSelected: z.boolean(),

		notifyEmail: z.union([
			z.string().email("Please provide a valid email address"),
			z.string().max(0).optional().nullable()
		]),

		notifySMS: z
			.string()
			.optional()
			.nullable()
	})

	// 1. Require recipientName when notificationReceipient is "notifyOthers"
	.refine(
		(data) => {
			if (data.notificationReceipient === "notifyOthers") {
				return data.recipientName && data.recipientName.length > 0;
			}
			return true;
		},
		{
			message: "Please provide the recipient's name",
			path: ["recipientName"]
		}
	)

	// 2. Require at least one method of notification to be selected (email or SMS)
	.refine(
		(data) => data.emailSelected || data.smsSelected,
		{
			message: "Please select at least one method of notification (Email or SMS)",
			path: ["emailSelected"]
		}
	)
	.refine(
		(data) => data.emailSelected || data.smsSelected,
		{
			message: "Please select at least one method of notification (Email or SMS)",
			path: ["smsSelected"]
		}
	)

	// 3. Validate email if emailSelected is true
	.refine(
		(data) => {
			if (data.emailSelected) {
				return data.notifyEmail && data.notifyEmail.length > 0;
			}
			return true;
		},
		{
			message: "Please provide a valid email address",
			path: ["notifyEmail"]
		}
	)

	// 4. Validate SMS if smsSelected is true
	.refine(
		(data) => {
			if (data.smsSelected) {
				return data.notifySMS && data.notifySMS.length > 0;
			}
			return true;
		},
		{
			message: "Please provide a valid phone number",
			path: ["notifySMS"]
		}
	);

export type NotificationRow = {
	id: number;
	contactID: number;
	active: boolean;
	created: string | null;
	createdBy: string | null;
	delete: boolean;
	email: string;
	lastModified: string | null;
	lastModifiedBy: string | null;
	notificationApplicationSystemName: string | null;
	notificationTemplateID: number | null;
	notificationTransportTypeID: number;
	notificationTypeID: number;
	objectID: number;
	objectType: string;
	outForDeliveryOnly: boolean;
	phoneNumber: string | null;
	recipentName: string;
	transportSettings: any | null; // Adjust based on the actual type of transportSettings
};

export type NotificationLookup = {
	systemName: string;
}

export interface SubscriptionFormProps {
	closeDialog: React.Dispatch<React.SetStateAction<boolean>>;
	isDialogOpen: boolean;
	query: any;
}

export function SubscriptionForm({ closeDialog, isDialogOpen, query }: SubscriptionFormProps) {
	const { userDetail } = useMyAccount();
	const theme = useTheme();
	const title = "Subscribe to Consignment " + query?.data?.data.reference;

	const [notificationLookup] = useLookupQuery<LookupItem<NotificationLookup>>({
		systemName: "NotificationType"
	});

	const [notificationTransportLookup] = useLookupQuery<LookupItem<NotificationLookup>>({
		systemName: "NotificationTransportType"
	});
	const popup = usePopupAlert();

	const notificationTypeID = useMemo(() => notificationLookup?.data?.data.results.find((val) => val.additionalData?.systemName === "ConsignmentNotification")?.id, [notificationLookup]);
	const notificationTransportTypeID = useMemo(() => {
		return notificationTransportLookup?.data?.data.results.find((val) => val.additionalData?.systemName === "Email")?.id;
	}, [notificationTransportLookup]);

	const [context, Form] = useForm<any>({
		defaultValues: {
			"notificationReceipient": null,
			"notificationType": null

		},
		validationSchema: subscriptionFormValidation
	});

	const { watch, setValue, getValues, handleSubmit } = context;
	const [existingNotifications, setExistingNotifications] = useState([]);
	const [loading, setLoading] = useState<boolean>(true);
	const [edit, setEdit] = useState<boolean>(false);
	const [id, setID] = useState<number>();
	const [newRecipient, setNewRecipient] = useState<boolean>(false);
	const [openDialog, setOpenDialog] = useState(false);
	const [selectedRow, setSelectedRow] = useState<NotificationRow>();
	const emailSelected = watch("emailSelected");
	const smsSelected = watch("smsSelected");
	const notificationReceipientValue = watch("notificationReceipient");
	const userDetailCombinedName = userDetail.firstName + " " + userDetail.lastName;

	const handleEdit = (data: NotificationRow) => {
		setEdit(true);
		setID(data.id);

		if (data.recipentName === userDetailCombinedName) {
			setValue("notificationReceipient", "notifyMe");
		} else {
			setValue("notificationReceipient", "notifyOthers");
			setValue("recipientName", data.recipentName);
		}

		if (data.outForDeliveryOnly) {
			setValue("notificationType", "outForDelivery");
		} else {
			setValue("notificationType", "allNotifications");
		}

		if (data.email) {
			setValue("notifyEmail", data.email);
			setValue("emailSelected", true);
		}
		if (data.phoneNumber) {
			setValue("smsSelected", true);
			setValue("notifySMS", data.phoneNumber);
		}
	};

	const handleOnDeleteClick = (data: NotificationRow) => {
		setSelectedRow(data);
		setOpenDialog(true);
	};

	const handleConfirmDelete = () => {
		if (selectedRow) {
			const body = [
				{
					id: selectedRow.id,
					contactID: userDetail.id,
					objectType: "Job",
					objectID: query.data?.data.id,
					notificationTypeID: selectedRow.notificationTypeID,
					notificationTemplateID: null,
					notificationTransportTypeID: selectedRow.notificationTransportTypeID,
					active: false,
					email: selectedRow.email,
					phoneNumber: selectedRow.phoneNumber,
					recipentName: selectedRow.recipentName,
					outForDeliveryOnly: selectedRow.outForDeliveryOnly
				}
			];
			httpService.post("BLLNotificationConfiguration/SaveList", body)
				.then((res) => {
					if (res.message === null) {
						popup({
							message: "Succesfully deleted subscription.",
							severity: "success"
						});
					}
					setOpenDialog(false);
					closeDialog(false);
				});
		}
	};

	const handleCancelDelete = () => {
		setOpenDialog(false);  // Close the confirmation dialog
	};

	const handleSubmitBtnClick = () => {
		handleSubmit(
			(state) => {
				const body = [
					{
						contactID: userDetail.id,
						objectType: "Job",
						objectID: query.data?.data.id,
						notificationTypeID,
						notificationTemplateID: null,
						notificationTransportTypeID,
						active: true,
						email: state.emailSelected ? state.notifyEmail : null,
						phoneNumber: state.smsSelected ? state.notifySMS : null,
						recipentName: state.notificationReceipient === "notifyMe" ? userDetailCombinedName : state.recipientName,
						outForDeliveryOnly: state.notificationType === "outForDelivery" ? true : false
					}
				];
				httpService.post("BLLNotificationConfiguration/SaveList", body)
					.then((res) => {
						if (res.message === null) {
							popup({
								message: "Succesfully subcribed",
								severity: "success"
							});
						}
						setNewRecipient(false);
						closeDialog(false);
					});
			},
			(error) => {
				console.debug("submit failed:", error);
			}
		)();
	};

	const handleSubmitEditBtnClick = () => {
		handleSubmit(
			(state) => {
				const body = [
					{
						id: id,
						contactID: userDetail.id,
						objectType: "Job",
						objectID: query.data?.data.id,
						notificationTypeID,
						notificationTemplateID: null,
						notificationTransportTypeID,
						active: true,
						email: state.emailSelected ? state.notifyEmail : null,
						phoneNumber: state.smsSelected ? state.notifySMS : null,
						recipentName: state.notificationReceipient === "notifyMe" ? userDetailCombinedName : state.recipientName,
						outForDeliveryOnly: state.notificationType === "outForDelivery" ? true : false
					}
				];
				httpService.post("BLLNotificationConfiguration/SaveList", body)
					.then((res) => {
						if (res.message === null) {
							popup({
								message: "Succesfully updated.",
								severity: "success"
							});
							setEdit(false);
							closeDialog(false);
						}
					});
			},
			(error) => {
				console.debug("submit failed:", error);
			}
		)();
	};

	const handleNewRecipient = () => {
		setNewRecipient(true);
	};

	const notificationReceipient: RadioGroupFieldOption[] = [
		{ value: "notifyMe", label: "Notify Me", hasTextField: false },
		{ value: "notifyOthers", label: "Notify someone else", hasTextField: false }
	];

	const notificationType: RadioGroupFieldOption[] = [
		{ value: "outForDelivery", label: "Out for Delivery", hasTextField: false },
		{ value: "allNotifications", label: "All Notifications: Booked, Intransit, Out for Delivery and Delivered", hasTextField: false }
	];

	useEffect(() => {
		setLoading(true);
		httpService.get(`BLLNotificationConfiguration/${query.data?.data.id}/Job`)
			.then(res => setExistingNotifications(res.data.filter((el: NotificationRow) => el.active === true)))
			.finally(() => {
				setLoading(false);
			});
	}, [query.data?.data.id]);


	useEffect(() => {
		if (smsSelected) {
			setValue("notifySMS", getValues("notifySMS"));
		}
		if (emailSelected) {
			setValue("notifyEmail", getValues("notifyEmail"));
		}
	}, [smsSelected, emailSelected]);

	const columns = [
		{ field: "recipentName", headerName: "Name", width: 150 },
		{ field: "email", headerName: "Email", width: 250 },
		{ field: "phoneNumber", headerName: "Phone", width: 200 },
		{
			field: "outForDeliveryOnly",
			headerName: "Notification Type",
			width: 150,
			renderCell: (params: any) => (params.value ? "Out for Delivery" : "All")
		},
		{
			field: "actions",
			headerName: "Actions",
			minWidth: 80,
			renderCell: (params: any) => {
				return (
					<>

						<Edit
							onClick={() => handleEdit(params.row)}
							style={{ cursor: "pointer" }}
						/>
						<DeleteOutline
							onClick={() => handleOnDeleteClick(params.row)}
							style={{ cursor: "pointer" }}
						/>
						<Dialog
							open={openDialog}
							onClose={handleCancelDelete}
						>
							<DialogTitle>Confirm Deletion</DialogTitle>
							<DialogContent>
								<DialogContentText>
									Are you sure you want to delete this subscription?
								</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button onClick={handleCancelDelete} color="primary">
									Cancel
								</Button>
								<Button onClick={handleConfirmDelete} color="secondary">
									Delete
								</Button>
							</DialogActions>
						</Dialog>

					</>
				);
			}
		}
	];

	return (
		!loading && (
			<DialogWrap
				maxWidth="md"
				fullWidth
				open={isDialogOpen}
				onCloseBtnClick={() => closeDialog(false)}
				title={title}
			>
				<Form>
					{(existingNotifications.length === 0 || newRecipient) ? (
						<>
							<RadioGroupField 
								name="notificationReceipient" 
								options={notificationReceipient} 
								onChange={(_event, value) => {
									if (value === "notifyMe") {
										setValue("notifySMS", userDetail.phoneNumber);
										setValue("notifyEmail", userDetail.email);
									} else {
										setValue("notifyEmail", "");
										setValue("notifySMS", "");
									}
								}} 
							/>
							<Divider />
							<RadioGroupFieldVerticle name="notificationType" options={notificationType} />

							<Box display={"flex"} flexDirection={"column"} py={5}>
								<Typography style={{ fontWeight: "bold" }}>Notify method:</Typography>
								{notificationReceipientValue === "notifyOthers" && (
									<FormElementWrap name="recipientName">
										<FormInput name="recipientName" placeholder="Receiver Name" />
									</FormElementWrap>
								)}
								<Box display="flex" alignItems="center">
									<FormCheckbox name="emailSelected" label={"Email"} />
								</Box>
								<Box display="flex" alignItems="center" mt={2}>
									<FormCheckbox name="smsSelected" label={"SMS"} />
								</Box>
								{emailSelected && (
									<FormElementWrap name="notifyEmail">
										<FormInput name="notifyEmail" placeholder="Email" onChange={(e) => setValue("notifyEmail", e)} />
									</FormElementWrap>
								)}
								{smsSelected && (
									<FormElementWrap name="notifySMS">
										<FormPhoneInput name="notifySMS" placeholder="SMS" />
									</FormElementWrap>
								)}
							</Box>
							<Box display="flex" justifyContent="flex-end">
								<Button type="submit" color="primary" variant="contained" disabled={notificationLookup.isFetching} onClick={handleSubmitBtnClick}>
									Subscribe
								</Button>
							</Box>
						</>
					) : (
						<>
							<Box display="flex" alignItems="center" padding="10px">
								<Add
									onClick={() => handleNewRecipient()}
									style={{ cursor: "pointer" }}
								/>
								<Typography variant="body2" style={{ marginLeft: 8 }}>
									Recipient
								</Typography>
							</Box>
							<DataGridPro
								columns={columns}
								rows={existingNotifications ?? []}
								hideFooter
								density="compact"
								sx={{
									"& .MuiDataGrid-columnHeaders": {
										backgroundColor: alpha(hexToRgb(theme.palette.common.black), 0.8)
									},
									"& .MuiDataGrid-columnHeaderTitle": {
										color: theme.palette.common.white,
										fontWeight: 600
									},
									"& .MuiDataGrid-sortIcon": {
										color: theme.palette.common.white
									},
									"& .MuiDataGrid-menuIconButton": {
										color: theme.palette.common.white
									},
									"& .MuiDataGrid-filterIcon": {
										color: theme.palette.common.white
									},
									"& .MuiDataGrid-columnSeparator:hover": {
										color: darken(theme.palette.common.white, 0.3)
									},
									"& .MuiDataGrid-row": {
										backgroundColor: theme.palette.common.white
									},
									"& .MuiDataGrid-overlay": {
										backgroundColor: "white"
									},
									"& .MuiDataGrid-virtualScrollerContent": {
										backgroundColor: "white",
										overflow: "auto",
										scrollbarGutter: "stable"
									}
								}}
							/>
							{(edit) &&
								<Box padding="20px">
									<RadioGroupField 
										name="notificationReceipient" 
										options={notificationReceipient} 
										onChange={(_event, value) => {
											if (value === "notifyMe") {
												setValue("notifySMS", userDetail.phoneNumber);
												setValue("notifyEmail", userDetail.email);
											} else {
												setValue("notifyEmail", "");
												setValue("notifySMS", "");
											}
										}} 
									/>
									<Divider />
									<RadioGroupFieldVerticle name="notificationType" options={notificationType} />

									<Box display={"flex"} flexDirection={"column"} py={5}>
										<Typography style={{ fontWeight: "bold" }}>Notify method:</Typography>
										{notificationReceipientValue === "notifyOthers" && (
											<FormElementWrap name="recipientName">
												<FormInput name="recipientName" placeholder="Receiver Name" />
											</FormElementWrap>
										)}
										<Box display="flex" alignItems="center">
											<FormCheckbox
												name="emailSelected"
												label={"Email"}
												onChange={(_event, checked) => {
													if (checked && notificationReceipientValue === "notifyMe") {
														setValue("notifyEmail", userDetail.email);
													}
												}}
											/>
										</Box>
										<Box display="flex" alignItems="center" mt={2}>
											<FormCheckbox
												name="smsSelected"
												label={"SMS"}
												onChange={(_event, checked) => {
													if (checked && notificationReceipientValue === "notifyMe") {
														setValue("notifySMS", userDetail.phoneNumber);
													}
												}}
											/>
										</Box>
										{emailSelected && (
											<FormElementWrap name="notifyEmail">
												<FormInput name="notifyEmail" placeholder="Email" onChange={(e) => setValue("notifyEmail", e)} />
											</FormElementWrap>
										)}
										{smsSelected && (
											<FormElementWrap name="notifySMS">
												<FormPhoneInput name="notifySMS" placeholder="SMS" />
											</FormElementWrap>
										)}
									</Box>
									<Box display="flex" justifyContent="flex-end">
										<Button type="submit" color="primary" variant="contained" disabled={notificationLookup.isFetching} onClick={handleSubmitEditBtnClick}>
											Update
										</Button>
									</Box>
								</Box>

							}
						</>
					)}
				</Form>
			</DialogWrap>
		)
	);
}

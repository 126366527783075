import { useTheme } from "@mui/material";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";
import { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { SxProps, Theme } from "@mui/material";

function InternalTitle({ title, sx }: { title: string; sx?: SxProps<Theme> }) {
	return (
		<Typography fontWeight={600} lineHeight="1rem" sx={{ ...sx }}>
			{title}
		</Typography>
	);
}

type CollapsableSectionProps = {
	title: string | React.ReactNode;
	collapsed?: boolean;
	withShadow?: boolean;
	grow?: boolean;
	sx?: SxProps<Theme>;
	headerSx?: {
		title?: SxProps<Theme>;
		header?: SxProps<Theme>;
	};
};
export function CollapsableSection({
	title,
	collapsed = false,
	withShadow = true,
	grow = false,
	sx,
	headerSx,
	children
}: React.PropsWithChildren<CollapsableSectionProps>) {
	const theme = useTheme();
	const [_collapsed, setCollapsed] = useState<boolean>(collapsed);
	const Icon = _collapsed ? ExpandMore : ExpandLess;
	const _title =
		typeof title === "string" ? (
			<InternalTitle title={title} sx={{ fontSize: "0.85rem", padding: theme.spacing(2), ...headerSx?.title }} />
		) : (
			<>{title}</>
		);
	const handleClick = (_: React.MouseEvent<HTMLButtonElement, MouseEvent>) => setCollapsed((x) => !x);

	const borderRadiusCollapsed = _collapsed ? "5px" : "5px 5px 0 0";

	return (
		<Box
			flexGrow={grow && !_collapsed ? 1 : 0}
			display="flex"
			flexDirection="column"
			bgcolor={theme.palette.common.white}
			border={1}
			borderColor={theme.palette.grey[200]}
			sx={{
				boxShadow: withShadow ? `1px 2px 4px ${theme.palette.grey[300]}` : "none",
				borderRadius: "5px"
			}}
		>
			<Box
				display="flex"
				alignItems="center"
				justifyContent="space-between"
				padding={4}
				borderBottom={_collapsed ? 0 : 1}
				borderColor={theme.palette.grey[200]}
				sx={{
					backgroundColor: theme.palette.primary.main,
					color: theme.palette.common.white,
					padding: theme.spacing(1),
					borderRadius: borderRadiusCollapsed,
					...headerSx?.header
				}}
			>
				{_title}
				<IconButton sx={{ padding: 0 }} onClick={handleClick}>
					<Icon
						style={{
							color: theme.palette.common.white
						}}
					/>
				</IconButton>
			</Box>

			{!_collapsed && (
				<Box
					padding={4}
					sx={{
						...sx,
						overflowY: "auto",
						"::-webkit-scrollbar": {
							width: "4px"
						},
						"::-webkit-scrollbar-thumb": {
							backgroundColor: theme.palette.grey[400],
							borderRadius: "2px"
						},
						"::-webkit-scrollbar-track": {
							backgroundColor: theme.palette.grey[100]
						}
					}}
				>
					{children}
				</Box>
			)}
		</Box>
	);
}

import { useTheme, alpha, hexToRgb } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ConsignmentJobEvent } from "@sal/onevent-portal";
import dayjs from "dayjs";

export function ConsignmentEventContent(
	event: ConsignmentJobEvent & {
		completedDate: string,
		locationName: string
	}
) {
	const theme = useTheme();
	const prefixToEventCodes = ["InTransit", "OutForDelivery"];

	return (
		<Box display="flex" flexDirection="column" gap={1}>
			<Box
				alignSelf="flex-start"
				display="flex"
				alignItems="center"
				justifyContent="center"
				paddingY={1}
				paddingX={3}
				bgcolor={alpha(hexToRgb(theme.palette.primary.main), 0.08)}
				border={1}
				borderColor={theme.palette.primary.main}
				borderRadius={theme.shape.borderRadius * 2}
			>
				<Typography fontSize="0.8rem" fontWeight={500} color={theme.palette.primary.main}>
					{event.milestoneName ?? event.eventName}
				</Typography>
			</Box>
			{event.completedDate && (
				<Typography fontSize="0.8rem" color={theme.palette.grey[600]}>
					{dayjs(event.completedDate).format("hh:mm A, DD MMM YYYY")}
				</Typography>
			)}
			{event.locationName && (
				<Typography fontSize="0.8rem">
					{event.eventCode && prefixToEventCodes.includes(event.eventCode) && 
						"To "
					}
					{event.locationName}
				</Typography>
			)}
		</Box>
	);
}
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { AuthenticatedRoute, ForgotPassword, NotFound, ResetPassword } from "@sal/portal";
import { lazy, Suspense } from "react";
import { HashRouter, Outlet, Routes, Route } from "react-router-dom";
import BasePage from "./pages/Layout/BasePage";
import { LoginPage } from "./pages/LoginPage";
import { routePermissionMapping } from "./appsettings";

const HomePage = lazy(() => import("./pages/HomePage"));
const ConsignmentDashboardPage = lazy(() => import("./pages/ConsignmentDashboardPage"));
const FreightBookingPage = lazy(() => import("./pages/FreightBookingPage"));
const ContainerBookingPage = lazy(() => import("./pages/ContainerBookingPage"));
const FreightTrackerPage = lazy(() => import("./pages/FreightTrackerPage"));
const RatesPage = lazy(() => import("./pages/RatesPage"));

const ContainerConsignmentTrackerPage = lazy(() => import("./pages/ContainerConsignmentTrackerPage"));
const TrackPage = lazy(() => import("./pages/TrackPage"));
const ConsignmentListPage = lazy(() => import("./pages/ConsignmentListPage"));
const NetworkHealthPage = lazy(() => import("./pages/NetworkHealthPage"));

const UserDashboardPage = lazy(() => import("./pages/UserDashboardPage"));
const UserDetailPage = lazy(() => import("./pages/UserDetailPage"));
const UserNewPage = lazy(() => import("./pages/UserNewPage"));
const MyAccountPage = lazy(() => import("./pages/MyAccountPage"));

const {
	dashboard,
	freightBooking,
	containerBooking,
	freightDetail,
	containerDetail,
	track,
	rates,
	consignmentList,
	networkHealth,
	users
} = routePermissionMapping;

export default function Router() {
	return (
		<HashRouter>
			<Routes>
				<Route element={<AuthenticatedRoute />}>
					<Route element={<BasePage />}>
						<Route
							element={
								<Suspense
									fallback={
										<Backdrop invisible open>
											<CircularProgress />
										</Backdrop>
									}
								>
									<Outlet />
								</Suspense>
							}
						>
							<Route path="/" element={<HomePage />} />
							<Route
								path={dashboard.path}
								element={
									<AuthenticatedRoute
										securityContext={dashboard.securityContext}
										securityContextPermission={dashboard.securityContextPermission}
									/>
								}
							>
								<Route path="" element={<ConsignmentDashboardPage />} />
							</Route>
							<Route path={freightBooking.path}>
								<Route
									path=""
									element={
										<AuthenticatedRoute
											securityContext={freightBooking.securityContext}
											securityContextPermission={freightBooking.securityContextPermission}
										/>
									}
								>
									<Route path="" element={<FreightBookingPage />} />
								</Route>
								<Route
									path=":id"
									element={
										<AuthenticatedRoute
											securityContext={freightBooking.securityContext}
											securityContextPermission={"Read"}
										/>
									}
								>
									<Route path="" element={<FreightBookingPage />} />
								</Route>
							</Route>
							<Route path={containerBooking.path}>
								<Route
									path=""
									element={
										<AuthenticatedRoute
											securityContext={containerBooking.securityContext}
											securityContextPermission={containerBooking.securityContextPermission}
										/>
									}
								>
									<Route path="" element={<ContainerBookingPage />} />
								</Route>
								<Route
									path=":id"
									element={
										<AuthenticatedRoute
											securityContext={containerBooking.securityContext}
											securityContextPermission={"Read"}
										/>
									}
								>
									<Route path="" element={<ContainerBookingPage />} />
								</Route>
							</Route>
							<Route
								path={freightDetail.path}
								element={
									<AuthenticatedRoute
										securityContext={freightDetail.securityContext}
										securityContextPermission={freightDetail.securityContextPermission}
									/>
								}
							>
								<Route path="" element={<FreightTrackerPage />} />
							</Route>
							<Route
								path={containerDetail.path}
								element={
									<AuthenticatedRoute
										securityContext={containerDetail.securityContext}
										securityContextPermission={containerDetail.securityContextPermission}
									/>
								}
							>
								<Route path="" element={<ContainerConsignmentTrackerPage />} />
							</Route>
							<Route
								path={track.path}
								element={
									<AuthenticatedRoute
										securityContext={track.securityContext}
										securityContextPermission={track.securityContextPermission}
									/>
								}
							>
								<Route path="" element={<TrackPage />} />
							</Route>
							<Route
								path={rates.path}
								element={
									<AuthenticatedRoute
										securityContext={rates.securityContext}
										securityContextPermission={rates.securityContextPermission}
									/>
								}
							>
								<Route path="" element={<RatesPage />} />
							</Route>
							<Route
								path={consignmentList.path}
								element={
									<AuthenticatedRoute
										securityContext={consignmentList.securityContext}
										securityContextPermission={consignmentList.securityContextPermission}
									/>
								}
							>
								<Route path="" element={<ConsignmentListPage />} />
							</Route>
							<Route
								path={networkHealth.path}
								element={
									<AuthenticatedRoute
										securityContext={networkHealth.securityContext}
										securityContextPermission={networkHealth.securityContextPermission}
									/>
								}
							>
								<Route path="" element={<NetworkHealthPage />} />
							</Route>

							{/* No permissions needed */}
							<Route path="/MyAccount" element={<MyAccountPage />} />

							{/* Permissions does not exist yet */}
							<Route
								path={users.path}
								element={
									<AuthenticatedRoute
										securityContext={users.securityContext}
										securityContextPermission={users.securityContextPermission}
									/>
								}
							>
								<Route path="" element={<UserDashboardPage />} />
								<Route path=":id" element={<UserDetailPage />} />
								<Route
									path="new"
									element={
										<AuthenticatedRoute
											securityContext={users.securityContext}
											securityContextPermission="Write"
										/>
									}
								>
									<Route path="" element={<UserNewPage />} />
								</Route>
							</Route>
						</Route>
					</Route>
				</Route>
				{/* Portal Framework */}
				<Route path="/Login" element={<LoginPage />} />
				<Route path="/ForgotPassword" element={<ForgotPassword />} />
				<Route path="/ResetPassword/:passwordResetToken" element={<ResetPassword />} />
				<Route path="/NotFound" element={<NotFound />} />

				<Route path="*" element={<NotFound />} />
				{/* Portal Framework */}
			</Routes>
		</HashRouter>
	);
}

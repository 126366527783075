import { type Settings as PortalFrameworkSettings } from "@sal/portal";
import { RoutePermissionSetting } from "@sal/onevent-portal";

type LayoutSettings = {
	"Orientation"?: "Vertical" | "Horizontal"
}

type AppSettings = {
	"Layout"?: LayoutSettings
	"PortalFramework": PortalFrameworkSettings
}

const AppSettings: AppSettings = {
	Layout: {
		Orientation: "Vertical"
	},
	PortalFramework: {
		logoUrl: "/images/logo.png",
		loginTitle: "Log into your account",
		defaultColorMode: "system"
	}
} as const;

export default AppSettings;

export const routePermissionMapping: { [key: string]: RoutePermissionSetting } = {
	dashboard: {
		path: "/Dashboard",
		securityContext: "portal_Menu",
		securityContextPermission: "Web_Consignment_Dashboard"
	},
	freightBooking: {
		path: "/FreightBooking",
		securityContext: "apI_FreightConsignment",
		securityContextPermission: "Write"
	},
	containerBooking: {
		path: "/ContainerBooking",
		securityContext: "apI_ContainerConsignment",
		securityContextPermission: "Write"
	},
	freightDetail: {
		path: "/Freight/:id",
		securityContext: "apI_FreightConsignment",
		securityContextPermission: "Read"
	},
	containerDetail: {
		path: "/Container/:id",
		securityContext: "apI_ContainerConsignment",
		securityContextPermission: "Read"
	},
	track: {
		path: "/Track",
		securityContext: "portal_Menu",
		securityContextPermission: "Web_TrackSearch"
	},
	rates: {
		path: "/Rates",
		securityContext: "portal_Menu",
		securityContextPermission: "Web_RatesPage"
	},
	consignmentList: {
		path: "/ConsignmentList",
		securityContext: "portal_Menu",
		securityContextPermission: "Web_Consignment_List"
	},
	networkHealth: {
		path: "/NetworkHealth",
		securityContext: "portal_Menu",
		securityContextPermission: "Web_Network_Health"
	},
	users: {
		path: "/Users",
		securityContext: "apI_User",
		securityContextPermission: "Read"
	}
};

export const placesRequestOptions = {
	autocompleteRequestOptions: {
		componentRestrictions: { country: "nz" }
	},
	placeDetailsRequestOptions: {
		fields: ["address_components", "formatted_address", "name", "place_id", "geometry"],
		region: "nz"
	}
};

export const dropboxDirectory = "/Apps/DocumentLink for iCOS LIVE/Bookings";
import { useAuth } from "@sal/authentication.react";
import { useTheme } from "@mui/material";
import { HorizontalNavigation } from "@sal/portal";
import { userHasSecurityContext } from "@sal/onevent-portal";
import { Outlet } from "react-router-dom";
import { SecurityContext } from "../../constants";
import { type LayoutProps } from "./";
import Box from "@mui/material/Box/Box";
import logo from "../../../public/images/logo.png";

type Auth = ReturnType<typeof useAuth>

export function HorizontalLayout({ menuItems }: LayoutProps) {
	const auth = useAuth();
	const theme = useTheme();

	/* Permissions; */
	const hasMenuPermission = (auth: Auth) => userHasSecurityContext(auth, SecurityContext.Menu);
	const hasUserMenuPermission = (auth: Auth) => auth.userHasPermission(SecurityContext.Host, "User_Menu");
	const hasChangePasswordPermission = (auth: Auth) => auth.userHasPermission(SecurityContext.Host, "Change_Password");
	/* Permissions; */

	return (
		<Box width="100%" height="100%" display="flex" flexDirection="column">
			{auth.isLoggedIn && (
				<Box
					flexGrow={0}
					bgcolor={theme.palette.common.white}
					color={theme.palette.text.primary}
				>
					<HorizontalNavigation
						permissions={{
							menu: hasMenuPermission,
							userMenu: hasUserMenuPermission
						}}
						clientLogo={logo}
						AuthenticatedUserMenuProps={{
							permissions: {
								changePassword: hasChangePasswordPermission
							}
						}}
						MenuProps={{
							items: menuItems
						}}
					/>
				</Box>
			)}
			<Box
				flexGrow={1}
				overflow="auto"
			>
				<Outlet />
			</Box>
		</Box>
	);
}

import { Grid } from "@mui/material";
import { FormLookup, InputLabel, usePortalApplicationContext } from "@sal/onevent-portal";
import { useEffect, useRef } from "react";
import { useFormContext } from "react-hook-form";

export function RatesFilter() {
	const { organisations: { getOrganisations } } = usePortalApplicationContext();
	const organisationID = getOrganisations()[0]?.additionalData?.ediCode;

	const { reset } = useFormContext();

	const isFirstChange = useRef(true);

	useEffect(() => {
		if (isFirstChange.current) {
			isFirstChange.current = false;
			return;
		}
		
		reset();
	}, [organisationID]);

	return (
		<Grid container spacing={2} alignItems="center">
			<Grid item md={12}>
				<Grid container spacing={2}>
					<Grid item xs={6} md={3}>
						<InputLabel name="origin" label="Origin" />
						<FormLookup
							name="origin"
							params={{
								systemName: "RateOrigin",
								additionalFilter: {
									organisationID: organisationID
								}
							}}
							readOnly={!organisationID}
							placeholder="Origin"
						/>

					</Grid>
					<Grid item xs={6} md={3}>
						<InputLabel name="destination" label="Destination" />
						<FormLookup
							name="destination"
							params={{
								systemName: "RateDestination",
								additionalFilter: {
									organisationID: organisationID
								}
							}}
							readOnly={!organisationID}
							placeholder="Desination"
						/>

					</Grid>
					<Grid item xs={6} md={3}>
						<InputLabel name="commodity" label="Commodity" />
						<FormLookup
							name="commodity"
							params={{
								systemName: "RateCommodity",
								additionalFilter: {
									organisationID: organisationID
								}
							}}
							readOnly={!organisationID}
							placeholder="Commodity"

						/>

					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

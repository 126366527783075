import { createTheme, darken, lighten, Theme as ITheme } from "@mui/material/styles";

const Theme: ITheme = createTheme(
	{
		components: {
			MuiCssBaseline: {
				styleOverrides: {
					body: {
						backgroundColor: "#EAEAF0"
					}
				}
			},
			MuiAppBar: {
				styleOverrides: {
					root: {
						backgroundColor: "#FFFFFF",
						boxShadow: "none",
						color: "inherit"
					}
				}
			},
			MuiAutocomplete: {
				styleOverrides: {
					inputRoot: {
						padding: 0
					}
				}
			},
			MuiButton: {
				styleOverrides: {
					root: {
						textTransform: "none"
					},
					containedPrimary: {
						"&:hover": {
							backgroundColor: lighten("#005F9E", 0.1)
						}
					},
					containedSecondary: {
						"&:hover": {
							backgroundColor: darken("#00aae7", 0.1)
						}
					},
					outlinedPrimary: {
						borderWidth: "2px",
						borderColor: "#005F9E",
						"&:hover": {
							borderWidth: "2px",
							borderColor: "#005F9E"
						}
					}
				}
			},
			MuiFormControl: {
				styleOverrides: {
					root: {
						backgroundColor: "#FFFFFF",
						borderColor: "#F7F7F7"
					}
				}
			},
			MuiCardContent: {
				styleOverrides: {
					root: {
						":last-child": {
							paddingBottom: "1rem"
						}
					}
				}
			}
		},
		palette: {
			primary: {
				main: "#005F9E"
			},
			secondary: {
				main: "#00aae7",
				light: "#eaf6fc"
			},
			background: {
				default: "#F7F7F7",
				paper: "#FFFFFF"
			},
			success: {
				main: "#aab733"
			},
			warning: {
				main: "#f9b42d"
			}
		},
		spacing: (factor: number) => `${0.25 * factor}rem`,
		typography: {
			allVariants: {
				fontFamily: "Inter, sans-serif"
			}
		}
	},
	{
		portalFramework: {
			pages: {
				login: {
					backgroundContainer: {}
				}
			}
		}
	}
);

export default Theme;

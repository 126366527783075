import { AddBox, IndeterminateCheckBox, Info } from "@mui/icons-material";
import { alpha, Box, darken, hexToRgb, IconButton, Tooltip, useTheme } from "@mui/material";
import { DataGridPro, GridRenderCellParams, GridValueFormatterParams, GridValueGetterParams } from "@mui/x-data-grid-pro";
import { LookupItem, Nullable } from "@origin/platform-types";
import { useGridQuery, usePortalApplicationContext } from "@sal/onevent-portal";
import { useEffect, useState } from "react";

interface RatesGridProps {
	origin: Nullable<LookupItem>;
	destination: Nullable<LookupItem>;
	commodity: Nullable<LookupItem>;
}

export interface RatesGrid {
	rateID: number
	origin: Nullable<string>
	destination: Nullable<string>
	minimum: Nullable<number>
	rate: Nullable<number>
	unit: Nullable<string>
	ratio: Nullable<string>
	commodity: Nullable<string>
	hasBreakPoint: Nullable<boolean>
	fromType: Nullable<string>
	toType: Nullable<string>
	originZoneAreas: Nullable<string>
	destinationZoneAreas: Nullable<string>
}

export interface RatesBreakPointGrid {
	id: number
	weight: Nullable<number>
	cubic: Nullable<number>
	rate: Nullable<number>
	RateType: Nullable<string>
}

export function RatesGrid({ origin, destination, commodity }: RatesGridProps) {
	const theme = useTheme();
	const [expandedRateID, setExpandedRateID] = useState<number | null>(null);
	const { organisations: { getOrganisations } } = usePortalApplicationContext();
	const organisationID = getOrganisations()[0]?.additionalData?.ediCode;

	useEffect(() => {
		if (expandedRateID) {
			setExpandedRateID(null);
		}
	}, [origin, destination, commodity, organisationID]);


	const [{ isFetching, data: gridRates }] = useGridQuery<RatesGrid>(
		{
			systemName: "RatesGrid",
			parameters: {
				additionalFilter: {
					organisationID: organisationID,
					originID: origin?.id ?? null,
					destinationID: destination?.id ?? null,
					commodity: commodity?.name ?? null,
					fromType: origin?.additionalData.fromType ?? null,
					toType: destination?.additionalData.toType ?? null
				}
			}
		},
		{ cacheTime: 0, enabled: !!organisationID }
	);

	const [{ isFetching: isFetchingGridRatesBreakPoint, data: gridRatesBreakPoint }] = useGridQuery<RatesBreakPointGrid>(
		{
			systemName: "RatesBreakPointGrid",
			parameters: {
				additionalFilter: {
					rateID: expandedRateID
				}
			}
		},
		{ cacheTime: 0, enabled: !!expandedRateID }
	);

	const rateGridColumns = [
		{
			field: "hasBreakPoint",
			flex: 1,
			headerName: "",
			maxWidth: 70,
			disableColumnMenu: true,
			renderCell: ({ value, row }: GridRenderCellParams<RatesGrid, RatesGrid["hasBreakPoint"]>) => {
				const handleExpandClick = () => {
					if (expandedRateID === row.rateID) {
						setExpandedRateID(null); // Collapse if it's already expanded
					} else {
						setExpandedRateID(row.rateID); // Expand the new rateID
					}
				};

				return value ? (
					<IconButton onClick={handleExpandClick}>
						{expandedRateID === row.rateID ? <IndeterminateCheckBox /> : <AddBox />}
					</IconButton>
				) : null;
			}
		},
		{
			field: "origin",
			flex: 1,
			headerName: "Origin",
			renderCell: ({ value, row }: GridRenderCellParams<RatesGrid, RatesGrid["origin"]>) => {
				if (row.fromType === "ZONE") {
					const areas = JSON.parse(row.originZoneAreas!);
					const areaNames = areas?.map((area: { AreaName: string }) => area.AreaName).join("\n");
					return (
						<Box display="flex" alignItems="center">
							<span>{value}</span>
							<Tooltip title={<pre>{areaNames}</pre>} arrow>
								<IconButton size="small" sx={{ marginLeft: 1 }}>
									<Info fontSize="small" />
								</IconButton>
							</Tooltip>
						</Box>
					);
				}

				return value ? value : "";
			}
		},
		{
			field: "destination",
			flex: 1,
			headerName: "Destination",
			renderCell: ({ value, row }: GridRenderCellParams<RatesGrid, RatesGrid["origin"]>) => {
				if (row.toType === "ZONE") {
					const areas = JSON.parse(row.destinationZoneAreas!);
					const areaNames = areas?.map((area: { AreaName: string }) => area.AreaName).join("\n");
					return (
						<Box display="flex" alignItems="center">
							<span>{value}</span>
							<Tooltip title={<pre>{areaNames}</pre>} arrow>
								<IconButton size="small" sx={{ marginLeft: 1 }}>
									<Info fontSize="small" />
								</IconButton>
							</Tooltip>
						</Box>
					);
				}

				return value ? value : "";
			}
		},
		{
			field: "minimum",
			flex: 1,
			headerName: "Minimum",
			valueFormatter: ({ value }: GridValueFormatterParams<string>) =>
				value ? `$${value}` : ""
		},
		{
			field: "rate",
			flex: 1,
			headerName: "Rate",
			valueFormatter: ({ value }: GridValueFormatterParams<string>) =>
				value ? `$${value}` : ""
		},
		{
			field: "unit",
			flex: 1,
			headerName: "Unit"
		},
		{
			field: "ratio",
			flex: 1,
			headerName: "Ratio",
			valueGetter: ({ value }: GridValueGetterParams<RatesGrid, RatesGrid["ratio"]>) =>
				value ? `1:${value}` : ""
		},
		{
			field: "commodity",
			flex: 1,
			headerName: "Commodity"
		}
	];

	const rateBreakPointGridColumns = [
		{
			field: "weight",
			flex: 1,
			headerName: "Break Point - Weight",
			valueGetter: ({ value }: GridValueGetterParams<RatesBreakPointGrid, RatesBreakPointGrid["weight"]>) =>
				value ? `Upto ≤ ${value} kg` : ""
		},
		{
			field: "cubic",
			flex: 1,
			headerName: "Break Point - Cubic",
			disableColumnMenu: true,
			renderCell: ({ value }: GridRenderCellParams<RatesBreakPointGrid, RatesBreakPointGrid["cubic"]>) => {
				return value ? `Upto ≤ ${value} m³` : "";
			}
		},
		{
			field: "rate",
			flex: 1,
			headerName: "Rate",
			disableColumnMenu: true,
			renderCell: ({ value }: GridRenderCellParams<RatesBreakPointGrid, RatesBreakPointGrid["rate"]>) => {
				return value ? `$${value}` : "";
			}
		},
		{
			field: "rateType",
			flex: 1,
			headerName: "Rate Type",
			disableColumnMenu: true
		}
	];

	return (
		<Box display="flex" flexDirection="column" height="100%">
			<Box
				flexGrow={0}
				flexBasis="70%"
				overflow="auto"
				sx={{
					backgroundColor: "white"
				}}
			>
				<DataGridPro<RatesGrid>
					columns={rateGridColumns}
					rows={gridRates?.data.rows ?? []}
					loading={isFetching}
					getRowId={(row) => row.rateID}
					hideFooter
					density="compact"
					sx={{
						"& .MuiDataGrid-columnHeaders": {
							backgroundColor: alpha(hexToRgb(theme.palette.common.black), 0.8)
						},
						"& .MuiDataGrid-columnHeaderTitle": {
							color: theme.palette.common.white,
							fontWeight: 600
						},
						"& .MuiDataGrid-sortIcon": {
							color: theme.palette.common.white
						},
						"& .MuiDataGrid-menuIconButton": {
							color: theme.palette.common.white
						},
						"& .MuiDataGrid-filterIcon": {
							color: theme.palette.common.white
						},
						"& .MuiDataGrid-columnSeparator:hover": {
							color: darken(theme.palette.common.white, 0.3)
						},
						"& .MuiDataGrid-row": {
							backgroundColor: theme.palette.common.white
						},
						"& .MuiDataGrid-overlay": {
							backgroundColor: "white"
						},
						"& .MuiDataGrid-virtualScrollerContent": {
							backgroundColor: "white",
							overflow: "auto",
							scrollbarGutter: "stable"
						}
					}}

				/>
			</Box>

			{expandedRateID !== null && (
				<Box
					flexGrow={0}
					flexBasis="30%"
					overflow="auto"
					sx={{
						backgroundColor: "white"
					}}
				>
					<DataGridPro<RatesBreakPointGrid>
						columns={rateBreakPointGridColumns}
						rows={gridRatesBreakPoint?.data.rows ?? []}
						loading={isFetchingGridRatesBreakPoint}
						getRowId={(row) => row.id}
						hideFooter
						density="compact"
						sx={{
							"& .MuiDataGrid-columnHeaders": {
								backgroundColor: alpha(hexToRgb(theme.palette.common.black), 0.8)
							},
							"& .MuiDataGrid-columnHeaderTitle": {
								color: theme.palette.common.white,
								fontWeight: 600
							},
							"& .MuiDataGrid-sortIcon": {
								color: theme.palette.common.white
							},
							"& .MuiDataGrid-menuIconButton": {
								color: theme.palette.common.white
							},
							"& .MuiDataGrid-filterIcon": {
								color: theme.palette.common.white
							},
							"& .MuiDataGrid-columnSeparator:hover": {
								color: darken(theme.palette.common.white, 0.3)
							},
							"& .MuiDataGrid-row": {
								backgroundColor: theme.palette.common.white
							},
							"& .MuiDataGrid-overlay": {
								backgroundColor: "white"
							},
							"& .MuiDataGrid-virtualScrollerContent": {
								backgroundColor: "white",
								overflow: "auto",
								scrollbarGutter: "stable"
							}
						}}

					/>
				</Box>
			)}
		</Box>
	);
}

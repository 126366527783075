import { Box, Button, Typography, useTheme } from "@mui/material";
import { getTerm, VerticalNavigationComponentProps } from "@sal/onevent-portal";
import AddIcon from "@mui/icons-material/Add";

export function VerticalNavigationActionButton({ collapsed }: VerticalNavigationComponentProps) {
	const theme = useTheme();
	const buttonPath = "/FreightBooking";

	return (
		<>
			<Box display="flex" alignItems="center" justifyContent="center" width="100%" paddingX={collapsed ? 0 : 6}>
				<Button
					variant="contained"
					color="secondary"
					fullWidth={!collapsed}
					title={getTerm("navigation_NewConsignment")}
					sx={{
						paddingY: collapsed ? theme.spacing(2) : theme.spacing(3),
						minWidth: collapsed ? "auto" : "initial"
					}}
					onClick={() => window.location.href = `/#${buttonPath}`}
				>
					<Typography
						fontSize={collapsed ? "large" : "0.85rem"}
						color={theme.palette.common.white}
						sx={{ display: "flex", alignItems: "center", gap: 1 }}
					>
						<AddIcon fontSize="inherit" />
						{!collapsed && <span>{getTerm("navigation_NewConsignment")}</span>}
					</Typography>
				</Button>
			</Box>
		</>
	);
}

import { LocationLookupItemAdditionalData } from "@sal/onevent-portal";
import { EMPTY_STRING } from ".";

export const addLookupAddress = (additionalData: LocationLookupItemAdditionalData) => {
	if (!additionalData) {
		return "";
	}

	const address = [
		additionalData?.address1,
		additionalData?.address2,
		additionalData?.suburb,
		additionalData?.postcode
	]
		.filter((part) => part !== null && part !== "")
		.join(", ");

	return address;
};

export const generateURL = (jobTypeSystemName: string, id: number | string, statusSystemName: string) => {
	const bookingPageStatusSystemNames = ["Quote", "Draft"];
	return `/${jobTypeSystemName.replace(/Consignment$/g, "")}${bookingPageStatusSystemNames.includes(statusSystemName) ? "Booking" : EMPTY_STRING}/${id}`;
};

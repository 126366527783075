import { memo } from "react";
import { useTheme } from "@mui/material/styles";
import WarningIcon from "@mui/icons-material/Warning";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import {
	GeoInformationRegionMapLayer,
	useGridQuery,
	useLookupQuery,
	type LocationLookupItem,
	type GeoCoordinateInformation,
	type GeoInformationRegionMapLayerRegion,
	type GeoInformationRegionMapLayerRegionStyles,
	type GeoInformationRegionMapLayerRegionSeverityGetter,
	type GeoInformationRegionMapLayerSeverityIconMap
} from "@sal/onevent-portal";
import dayjs from "dayjs";
import { defaultValue } from "../utilities";
import { SystemName } from "../constants/systemname";

const activeFrom = dayjs().format("YYYY-MM-DD HH:mm:ss");

export const NetworkHealthMapLayer = memo(function NetworkHealthMapLayer() {
	const theme = useTheme();

	const [{ data: regions }] = useLookupQuery<LocationLookupItem>(
		{
			systemName: "Location",
			additionalFilter: {
				locationType: ["GeoInformationRegion"]
			}
		},
		{
			staleTime: Number.POSITIVE_INFINITY
		}
	);
	const [{ data: geoInfo }] = useGridQuery<GeoCoordinateInformation>(
		{
			systemName: "GeoCoordinateInformation",
			parameters: {
				activeFrom,
				displayOn: "MAP"
			}
		}
	);

	const mapLayerRegions: Array<GeoInformationRegionMapLayerRegion> =
		defaultValue(regions?.data?.results, []).map((region) => ({
			id: +region.id,
			name: region.name,
			placeId: region.additionalData!.combinedCode!,
			latitude: region.additionalData!.latitude!,
			longitude: region.additionalData!.longitude!
		}));

	const defaultRegionStyles: google.maps.FeatureStyleOptions = {
		strokeColor: theme.palette.grey[500],
		strokeOpacity: 0.5,
		strokeWeight: 1.0
	};
	const regionStyles: GeoInformationRegionMapLayerRegionStyles = {
		"Severe": {
			...defaultRegionStyles,
			strokeColor: theme.palette.error.main,
			fillColor: theme.palette.error.main,
			fillOpacity: 0.2
		},
		"Moderate": {
			...defaultRegionStyles,
			strokeColor: theme.palette.warning.main,
			fillColor: theme.palette.warning.main,
			fillOpacity: 0.2
		},
		"Information": {
			...defaultRegionStyles,
			strokeColor: theme.palette.secondary.main,
			fillColor: theme.palette.secondary.main,
			fillOpacity: 0.1
		}
	};

	const getRegionSeverity: GeoInformationRegionMapLayerRegionSeverityGetter = (region) => {
		return region.some(
			(x) => x.geoCoordinateInformationTypeSystemName === SystemName.GeoCoordinateInformationType.SignificantEvent)
			? "Severe"
			: region.some(
				(x) => x.geoCoordinateInformationTypeSystemName === SystemName.GeoCoordinateInformationType.GeneralEvent)
				? "Moderate"
				: region.some(
					(x) => x.geoCoordinateInformationTypeSystemName === SystemName.GeoCoordinateInformationType.BoothsNotification)
					? "Information"
					: null;
	};

	const severityIconMap: GeoInformationRegionMapLayerSeverityIconMap = {
		"Severe": <WarningIcon fontSize="inherit" color="error" />,
		"Moderate": <WarningIcon fontSize="inherit" color="warning" />,
		"Information": <NotificationsOutlinedIcon fontSize="inherit" color="inherit" />
	} as const;

	return (
		<GeoInformationRegionMapLayer
			regions={mapLayerRegions}
			geoCoordinateInformation={geoInfo?.data?.rows ?? []}
			regionStyles={regionStyles}
			severityIconMap={severityIconMap}
			getRegionSeverity={getRegionSeverity}
		/>
	);
});

import { ContentCopy as ContentCopyIcon } from "@mui/icons-material";
import { Box, Button, DialogProps } from "@mui/material";
import { DialogWrap, Input, useUserSetting } from "@sal/onevent-portal";

export interface ShareDialogProps extends Omit<DialogProps, "open"> {
	setIsOpenShareDialog: React.Dispatch<React.SetStateAction<boolean>>;
	isDialogOpen: boolean;
	reference: string;
}

export function ShareDialog({ setIsOpenShareDialog, isDialogOpen, reference }: ShareDialogProps) {
	const [trackURL] = useUserSetting({settingSystemName: "PublicTrackingURL", settingTypeSystemName: "Value"});
	const shareURL = `${trackURL}results/?reference=${reference}`;

	const handleOnCopy = async () => {
		if (shareURL) {
			try {
				await navigator.clipboard.writeText(shareURL);
			} catch (e) {
				if (e instanceof Error) {
					console.log("Copy to clipboard failed:", e);
				}
			}
		}
	};

	if (!isDialogOpen) {
		return <></>;
	}

	return (
		<DialogWrap
			maxWidth="md"
			fullWidth
			open={isDialogOpen}
			onCloseBtnClick={() => setIsOpenShareDialog(false)}
			title={"Share"}
		>
			<Box display={"flex"} gap={2}>
				<Input value={shareURL} readOnly/>
				<Button variant="contained" startIcon={<ContentCopyIcon />} onClick={handleOnCopy}>
					Copy
				</Button>
			</Box>
		</DialogWrap>
	);
}

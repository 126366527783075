import { AppBar, Toolbar, useTheme } from "@mui/material";
import { useAuth } from "@sal/authentication.react";
import { AuthenticatedUserMenu } from "@sal/portal";
import { UserOrganisationToggle } from "@sal/onevent-portal";
import { Outlet, useNavigate } from "react-router-dom";
import { SecurityContext } from "../../constants";
import { type LayoutProps } from ".";
import Box from "@mui/material/Box/Box";
import { VerticalNavigationActionButton } from "../../components/VerticalNavigationActionButton";
import IconButton from "@mui/material/IconButton";
import SettingsIcon from "@mui/icons-material/Settings";
import { VerticalNavigation } from "./VerticalNavigation";
import { QuickSearch } from "../../components";

type Auth = ReturnType<typeof useAuth>;

export function VerticalLayout({ menuItems }: LayoutProps) {
	const auth = useAuth();
	const theme = useTheme();
	const navigate = useNavigate();

	/* Permissions; */
	const hasUserMenuPermission = (auth: Auth) => auth.userHasPermission(SecurityContext.Host, "User_Menu");
	const hasBookingPermission = (auth: Auth) => auth.userHasPermission("apI_FreightConsignment", "Write") && auth.userHasPermission("apI_ContainerConsignment", "Write");
	/* Permissions; */

	const handleSettingIconClick = () => {
		navigate("MyAccount");
	};

	return (
		<>
			{auth.isLoggedIn && (
				<Box flexGrow={0} sx={{ backgroundColor: "#000000" }}>
					<VerticalNavigation
						width={{
							expanded: 250,
							collapsed: 75
						}}
						clientLogo={{
							// expanded: "/images/Booths_Logo.svg",
							expanded: "/images/Booths_B_Connected_Short.svg",
							collapsed: "/images/Booths_B_Logo.svg"
						}}
						clientLogoStyle={{
							expanded: {
								height: "5.5rem"
							},
							collapsed: {
								height: "1.55rem"
							}
						}}
						components={{
							Header: hasBookingPermission(auth) ? VerticalNavigationActionButton : undefined
						}}
						MenuProps={{
							items: menuItems,
							menuItemsStyles: {
								color: theme.palette.common.white,
								opacity: 0.35
							}
						}}
					/>
				</Box>
			)}
			{/* Set minWidth=0, to allow datagrid layouts with flex layout columns */}
			{/* https://github.com/mui/mui-x/issues/8895#issuecomment-1537134652 */}
			<Box flexGrow={1} minWidth={0} display="flex" flexDirection="column">
				{auth.isLoggedIn && (
					<Box flexGrow={0}>
						<AppBar
							position="static"
							sx={{
								borderBottom: "1px solid",
								borderColor: theme.palette.grey[200]
							}}
						>
							<Toolbar
								disableGutters
								sx={{
									alignItems: "stretch",
									paddingX: theme.spacing(8)
								}}
							>
								<Box display="flex" width="100%" alignItems="center" justifyContent="flex-end">
									<Box
										display="flex"
										flexGrow="1"
										alignSelf="stretch"
										alignItems={"center"}
										color={theme.palette.text.primary}
									>
										<Box width="100%" minWidth="200px" maxWidth="500px" pr={5}>
											<QuickSearch />
										</Box>
									</Box>
									<Box
										flexGrow="0"
										display="flex"
										alignItems="center"
										alignSelf="stretch"
										gap={3}
										color={theme.palette.text.primary}
									>
										<Box alignItems="center" minWidth="300px" maxWidth="400px">
											<UserOrganisationToggle allowSingleSelect />
										</Box>
										<Box
											flexGrow={0}
											display="flex"
											alignItems="center"
											alignSelf="stretch"
											color={theme.palette.text.primary}
										>
											{hasUserMenuPermission(auth) && <AuthenticatedUserMenu />}
										</Box>
										<IconButton color="inherit" onClick={handleSettingIconClick}>
											<SettingsIcon />
										</IconButton>
									</Box>
								</Box>
							</Toolbar>
						</AppBar>
					</Box>
				)}
				<Box
					flexGrow={1}
					overflow="auto"
					sx={{
						scrollbarGutter: "stable"
					}}
				>
					<Outlet />
				</Box>
			</Box>
		</>
	);
}

import { ToggleGroup } from "@sal/onevent-portal";
import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

export type ToggleOptions = {
	value: string;
	label: string;
	additionalData?: {
		path: string;
		title?: string;
	};
};
interface ConsignmentButtonNavigationProps {
	children?: React.ReactNode;
	onChange: (value: ToggleOptions | undefined) => void;
	options: ToggleOptions[];
	initialValue?: string;
}

export function ConsignmentButtonNavigation({
	children,
	onChange,
	initialValue,
	options
}: ConsignmentButtonNavigationProps) {
	const [selected, setSelected] = useState<string>(
		initialValue ? initialValue : options.length > 0 && options[0].value ? options[0].value : ""
	);
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	const handleToggleChange = (event: unknown, newValue: string) => {
		if (newValue !== null) {
			const selectedOption = options.find((option) => option.value === newValue);

			if (typeof onChange === "function") {
				onChange(selectedOption);
			}

			setSelected(selectedOption?.value ?? "");

			if (selectedOption && selectedOption.additionalData?.path) {
				navigate({ pathname: selectedOption.additionalData.path, search: searchParams.toString() });
			}
		}
	};

	return (
		<>
			<ToggleGroup
				options={options}
				value={selected}
				onChange={(event, newValue) => handleToggleChange(event, newValue)}
			/>
			{children}
		</>
	);
}

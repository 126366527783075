import { DialogProps, IconButton } from "@mui/material";
import { SubscriptionForm } from "./SubscriptionForm";

export interface SubscriptionDialogProps extends Omit<DialogProps, "open"> {
	query: any;
	closeDialog: any;
	isDialogOpen: boolean;
}

export function SubscriptionDialog({ children, closeDialog, isDialogOpen, query }: SubscriptionDialogProps) {
	return (
		<>
			<IconButton title="Notify me" >
				{children}
			</IconButton>

			<SubscriptionForm
				isDialogOpen={isDialogOpen}
				closeDialog={closeDialog}
				query={query}
			/>
		</>
	);
}

import { useState } from "react";
import { ConsignmentButtonNavigation, ToggleOptions } from "./ConsignmentButtonNavigation";
import { Box, Stack, useTheme } from "@mui/material";
import { PageHeader } from "../PageHeader";
interface ConsignmentHeaderLayoutProps {
	children?: React.ReactNode;
	initialValue: string;
	options: ToggleOptions[];
}

export function ConsignmentHeaderLayout({ children, initialValue, options }: ConsignmentHeaderLayoutProps) {
	const theme = useTheme();
	const [title, setTitle] = useState<string>(
		initialValue ? options.find((option) => option.value === initialValue)?.additionalData?.title ?? "" : ""
	);

	return (
		<Box width="100%" height="100%" display="flex" flexDirection="column">
			<Box
				flexGrow={0}
				paddingTop={theme.spacing(8)}
				paddingBottom={theme.spacing(6)}
				paddingX={theme.spacing(16)}
				bgcolor={theme.palette.common.white}
			>
				<Stack direction="row" spacing={2} justifyContent="space-between">
					<Box flexShrink={0} width="35%">
						<PageHeader title={title ? title : ""} />
					</Box>
					<Box flexShrink={0} width="60%">
						<ConsignmentButtonNavigation
							onChange={(value) => {
								setTitle(value?.additionalData?.title ?? "");
							}}
							initialValue={initialValue}
							options={options}
						/>
					</Box>
				</Stack>
			</Box>
			<Box flex={`0 0 ${theme.spacing(12)}`} bgcolor={theme.palette.common.white} />
			<Box
				flexGrow={1}
				marginTop={`-${theme.spacing(12)}`}
				paddingBottom={theme.spacing(4)}
				paddingX={theme.spacing(16)}
			>
				{children}
			</Box>
		</Box>
	);
}

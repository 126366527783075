import { useTheme } from "@mui/material";
import { MenuItem, MobileMenuDialog } from "@sal/portal";
import { useAuth } from "@sal/authentication.react";
import { useState } from "react";
import Grid from "@mui/material/Grid/Grid";

type ProvidePermissionResult = (auth: ReturnType<typeof useAuth>) => boolean;

type MobileNavigationPermissions = {
	userMenu: ProvidePermissionResult
	changePassword: ProvidePermissionResult
}

type MobileNavigationProps = {
	menuItems: Array<MenuItem>
	permissions: MobileNavigationPermissions
}
export function MobileNavigation({ menuItems, permissions }: MobileNavigationProps) {
	const theme = useTheme();
	const [menuOpen, setMenuOpen] = useState<boolean>(false);

	return (
		<Grid
			container
			alignItems="center"
			justifyContent="flex-end"
			padding={4}
			sx={{
				height: "3.5rem",
				maxHeight: "3.5rem",
				backgroundColor: theme.palette.primary.main
			}}
		>
			<MobileMenuDialog
				open={menuOpen}
				setOpen={setMenuOpen}
				menuItems={menuItems}
				permissions={permissions}
			/>
		</Grid>
	);
}

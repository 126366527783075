const consignment = {
	"consignor": "Shipper",
	"consignee": "Receiver",
	"pickedUpRequiredBy": "Required Pickup",
	"consignment.deliveryArea": "Delivery Area",
	"consignment.unpackArea": "Unpack Area",
	"consignment.exportArea": "Export Area",
	"consignment.dropoffArea": "Dropoff Area",
	"consignment.dehireArea": "Dehire Area",
	"consignment.packArea": "Pack Area",
	"consignment.pickupArea": "Pickup Area"
};

const base = {
	projectTitle: "BLL Portal",
	navigation_NewConsignment: "New Booking",
	...consignment
};

export { base };


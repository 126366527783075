import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { IconButton, useTheme } from "@mui/material";
import { useAuth } from "@sal/authentication.react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import React, { useEffect, useState } from "react";
import { VerticalMenu, VerticalMenuProps } from "@sal/onevent-portal";
import { defaultValue } from "../../utilities";

export type VerticalNavigationComponentProps = {
	collapsed: boolean;
};

type VerticalNavigationProps = {
	width?: {
		expanded: string | number;
		collapsed: string | number;
	};
	clientLogo: {
		expanded: string;
		collapsed: string;
	};
	clientLogoStyle?: {
		expanded?: React.CSSProperties;
		collapsed?: React.CSSProperties;
	};
	components?: {
		Header?: React.ComponentType<VerticalNavigationComponentProps>;
	};
	MenuProps: Omit<VerticalMenuProps, "collapsed">;
};

const DefaultRender = (_props: VerticalNavigationComponentProps) => null;

export function VerticalNavigation({
	width = { expanded: 275, collapsed: 75 },
	clientLogo,
	clientLogoStyle,
	components,
	MenuProps
}: VerticalNavigationProps) {
	const auth = useAuth();
	const theme = useTheme();
	const storageKey = `${window.btoa(auth.user!.userName)}_vertical-navigation`;
	const storedState = JSON.parse(window.sessionStorage.getItem(storageKey) ?? JSON.stringify({ collapsed: false }));
	const [collapsed, setCollapsed] = useState<boolean>(storedState.collapsed!);
	const _width = collapsed ? width.collapsed : width.expanded;

	const clientLogoCollapsedHeight =
		collapsed && clientLogoStyle?.collapsed?.height ? clientLogoStyle.collapsed.height : "1.25rem";
	const clientLogoExpandedHeight =
		!collapsed && clientLogoStyle?.expanded?.height ? clientLogoStyle.expanded.height : "1.25rem";

	useEffect(() => {
		window.sessionStorage.setItem(storageKey, JSON.stringify({ collapsed }));
	}, [collapsed]);

	const Header = defaultValue(components?.Header, DefaultRender);
	return (
		<Drawer
			variant="permanent"
			anchor="left"
			sx={{
				width: _width,
				"& .MuiDrawer-paper": {
					width: _width,
					boxSizing: "border-box",
					backgroundColor: "inherit"
				}
			}}
		>
			<Box display="flex" flexDirection="column" height="100%">
				<Box
					flexGrow={0}
					display="flex"
					flexDirection={collapsed ? "column" : "row"}
					alignItems="center"
					justifyContent="space-between"
					paddingX={6}
					paddingTop={collapsed ? 12 : 8}
					gap={collapsed ? 4 : 0}
				>
					<img
						src={collapsed ? clientLogo.collapsed : clientLogo.expanded}
						style={{
							height: collapsed ? clientLogoCollapsedHeight : clientLogoExpandedHeight
						}}
					/>
					<IconButton
						title={collapsed ? "Expand" : "Collapse"}
						sx={{
							padding: 0,
							marginRight: collapsed ? 0 : "-8px",
							color: theme.palette.primary.main,
							fontSize: "2rem"
						}}
						onClick={() => setCollapsed((x) => !x)}
					>
						{collapsed ? (
							<ChevronRight color="inherit" fontSize="inherit" />
						) : (
							<ChevronLeft color="inherit" fontSize="inherit" />
						)}
					</IconButton>
				</Box>
				<Box
					flexGrow={0}
					display="flex"
					alignItems="center"
					justifyContent="center"
					paddingTop={collapsed ? 4 : 0}
				>
					<Header collapsed={collapsed} />
				</Box>
				<Box
					flexGrow={1}
					display="flex"
					flexDirection="column"
					paddingY={6}
					paddingX={collapsed ? 4 : 6}
					sx={{
						overflowY: "auto"
					}}
				>
					<VerticalMenu {...MenuProps} collapsed={collapsed} />
				</Box>
			</Box>
		</Drawer>
	);
}

export default VerticalNavigation;

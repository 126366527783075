import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import { PageHeader, PageHeaderProps } from "./PageHeader";

type PageLayoutProps = PageHeaderProps;
export function PageLayout({ title, children }: React.PropsWithChildren<PageLayoutProps>) {
	const theme = useTheme();
	return (
		<Box width="100%" height="100%" display="flex" flexDirection="column">
			<Box
				flexGrow={0}
				paddingTop={theme.spacing(8)}
				paddingBottom={theme.spacing(6)}
				paddingX={theme.spacing(16)}
				bgcolor={theme.palette.common.white}
			>
				<PageHeader title={title} />
			</Box>
			<Box flex={`0 0 ${theme.spacing(12)}`} bgcolor={theme.palette.common.white} />
			<Box
				flexGrow={1}
				marginTop={`-${theme.spacing(12)}`}
				paddingBottom={theme.spacing(4)}
				paddingX={theme.spacing(16)}
			>
				{children}
			</Box>
		</Box>
	);
}
